
/* global google */
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
  export default class InteractiveMap extends Vue {
  @Prop({ type: String, default: '' }) readonly value!: string

  address: string = ''
  embedUrl: string = ''
  currentAddress: string = ''
  center = { lat: -33.4489, lng: -70.6693 }
  map: google.maps.Map | null = null
  marker: any = null // Se usará AdvancedMarkerElement
  showMap: boolean = false

  dialogExpand: boolean = false
  expandedMap: google.maps.Map | null = null
  expandedMarker: any = null

  get inputValue (): string {
    return this.embedUrl ? this.embedUrl : this.address
  }

  set inputValue (val: string) {
    this.address = val
    this.embedUrl = ''
    this.$emit('input', '')
  }

  mounted () {
    // El mapa se inicializa al desplegarlo
  }

  // Función de geocodificación inversa: a partir de latitud y longitud obtiene la dirección
  getAddressFromLatLng (lat: number, lng: number) {
    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results && results.length > 0) {
        this.currentAddress = results[0].formatted_address
      } else {
        console.error('No se pudo obtener la dirección:', status)
      }
    })
  }

  // Crea un marcador avanzado usando AdvancedMarkerElement y PinElement
  async createAdvancedMarker (map: google.maps.Map, position: google.maps.LatLngLiteral) {
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker')
    const pin = new PinElement({
      scale: 1.5,
      background: '#F03738',
    })
    return new AdvancedMarkerElement({
      map,
      position,
      title: 'Marcador avanzado',
      content: pin.element,
    })
  }

  // Inicializa el mapa principal y el marcador avanzado
  async initMap () {
    if (!this.map) {
      const container = this.$refs.mapContainer as HTMLElement
      this.map = new google.maps.Map(container, {
        center: this.center,
        zoom: 14,
        fullscreenControl: false,
        mapId: 'MAP_ID',
      })

      // Al hacer clic en el mapa, se mueve el marcador y se obtiene la dirección
      this.map.addListener('click', (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
          this.center = { lat: event.latLng.lat(), lng: event.latLng.lng() }
          if (this.marker) {
            this.marker.position = this.center
          }
          this.emitMapUrl()
          this.getAddressFromLatLng(this.center.lat, this.center.lng)
        }
      })
    }
    if (!this.marker) {
      this.marker = await this.createAdvancedMarker(this.map, this.center)
      this.emitMapUrl()
      this.getAddressFromLatLng(this.center.lat, this.center.lng)
    }
  }

  // Inicializa el mapa ampliado en el diálogo y su marcador avanzado
  async initExpandedMap () {
    const container = this.$refs.expandedMapContainer as HTMLElement
    if (!this.expandedMap) {
      this.expandedMap = new google.maps.Map(container, {
        center: this.center,
        zoom: 14,
        fullscreenControl: false,
        mapId: 'MAP_ID',
      })
      this.expandedMap.addListener('click', (event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
          this.center = { lat: event.latLng.lat(), lng: event.latLng.lng() }
          if (this.expandedMarker) {
            this.expandedMarker.position = this.center
          }
          this.emitMapUrl()
          this.getAddressFromLatLng(this.center.lat, this.center.lng)
        }
      })
    }
    if (!this.expandedMarker) {
      const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker')
      const pin = new PinElement({
        scale: 1.5,
        background: '#F03738',
      })
      this.expandedMarker = new AdvancedMarkerElement({
        map: this.expandedMap,
        position: this.center,
        title: 'Marcador ampliado',
        content: pin.element,
      })
    } else {
      this.expandedMap.setCenter(this.center)
      this.expandedMarker.position = this.center
    }
  }

  // Busca la dirección ingresada y centra el mapa
  async searchAddress () {
    this.showMap = true
    this.$nextTick(async () => {
      await this.initMap()
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: this.address }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
          const location = results[0].geometry.location
          this.center = { lat: location.lat(), lng: location.lng() }
          if (this.map) {
            this.map.setCenter(this.center)
          }
          if (this.marker) {
            this.marker.position = this.center
          }
          this.emitMapUrl()
          this.getAddressFromLatLng(this.center.lat, this.center.lng)
        } else {
          alert('No se encontró la dirección: ' + status)
        }
      })
    })
  }

  // Muestra el mapa y solicita la ubicación actual usando geolocalización
  async toggleMapAndLocate () {
    this.showMap = true
    this.$nextTick(async () => {
      await this.initMap()
      this.useCurrentLocation()
    })
  }

  // Usa geolocalización para centrar el mapa y obtener la dirección
  useCurrentLocation () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        if (this.map) {
          this.map.setCenter(this.center)
        }
        if (this.marker) {
          this.marker.position = this.center
        }
        this.emitMapUrl()
        this.getAddressFromLatLng(this.center.lat, this.center.lng)
      }, error => {
        alert('Error al obtener la ubicación: ' + error.message)
      })
    } else {
      alert('La geolocalización no es soportada por este navegador.')
    }
  }

  get isValidUrl () {
    const validUrl = this.embedUrl.includes('https://www.google.com/maps?q=')
    if (validUrl) {
      this.showMap = true
      this.$nextTick(() => {
        this.initMap()
      })
    }
    return validUrl
  }

  // Genera la URL embebida y la emite al componente padre
  emitMapUrl () {
    const url = `https://www.google.com/maps?q=${this.center.lat},${this.center.lng}&hl=es;z=14&output=embed`
    this.embedUrl = url
    this.$emit('input', url)
  }

  // Abre el diálogo con el mapa ampliado
  openDialog () {
    this.dialogExpand = true
    this.$nextTick(() => {
      this.initExpandedMap()
    })
  }

  // Cierra el diálogo
  closeDialog () {
    this.dialogExpand = false
  }

  @Watch('value', { immediate: true })
  onValueChange (val: string) {
    if (val !== this.inputValue) {
      this.embedUrl = val
      const regex = /maps\?q=([-.\d]+),([-.\d]+)/
      const match = val.match(regex)
      if (match) {
        this.center = { lat: parseFloat(match[1]), lng: parseFloat(match[2]) }
        if (this.map) {
          this.map.setCenter(this.center)
          if (this.marker) {
            this.marker.position = this.center
          }
          this.getAddressFromLatLng(this.center.lat, this.center.lng)
        }
      }
    }
  }
  }
